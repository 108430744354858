* {
  font-family: "Quicksand", "Rubik", sans-serif;
}
:root {
  --blue: #176fbf;
}
body {
  margin: 0;
  position: relative;
  background-color: #946dbf !important;
  min-height: 100vh;
  z-index: 1;
  overflow-x: hidden;
}

header {
  padding: 40px 0;
  position: relative;
  z-index: 4;
}
.text-blue {
  color: var(--blue) !important;
}
.btn-1 {
  background-color: var(--blue);
  border-radius: 25px;
  padding: 10px 30px;
  border: 0;
  color: white;
  font-weight: 500;
  font-size: 18px;
}
.purple-cover-circle {
  position: absolute;
  width: 1100px;
  height: 1100px;
  border-radius: 50%;
  left: -398px;
  top: -246px;

  background: rgba(177, 98, 214, 0.61);
  filter: blur(156px);
  z-index: -1;
}
.blue-cover-circle {
  /* Ellipse 4 */

  position: absolute;
  width: 1700px;
  height: 1700px;
  border-radius: 50%;
  left: 0;
  top: 0;
  transform: translate(-30%, -40%);

  background: #00bcd3;
  opacity: 0.5;
  filter: blur(156px);
  z-index: -2;
}

.hero-content {
  padding: 150px 0;
}
.hero-text h1 {
  font-size: 75px;
  font-weight: 500;
  font-size: 72px;
  line-height: 80px;
  letter-spacing: 2px;
  color: #ffffff;
}
.hero-text p {
  color: white;
  margin: 35px 0;
  line-height: 2.2;
}
.modal-section {
  position: relative;
  min-height: 800px;
  padding-top: 100px;
}
.modal-section::before {
  content: "";
  position: absolute;
  left: 0;
  width: 50%;
  height: 100%;
  background-image: url(./assets/modal-bg-1.png);
  background-size: contain;
  background-position: left;
  background-repeat: no-repeat;
  z-index: 4;
}
.modal-section::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  z-index: 4;
  width: 50%;
  height: 100%;
  background-image: url(./assets/modal-bg-2.png);
  background-size: contain;
  background-position: right;

  background-repeat: no-repeat;
}

.modal-wrapper {
  position: relative;
  z-index: 5;
  background-color: white;
  color: black;
  max-width: 650px;
  margin: 0 auto;
  border-radius: 25px;
  padding: 60px 80px;
  text-align: center;
}
.modal-wrapper p {
  line-height: 1.7;
}
.modal-wrapper span {
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 10px;
}
.modal-wrapper input {
  border: 0;
  border-bottom: 1px solid var(--blue);
  margin-bottom: 40px;
  outline: none !important;
}
.modal-wrapper .btn-1 {
  width: 60%;
}
.cta {
  position: relative;
  padding-block: 100px 150px;
}
.cta div {
  position: relative;
  z-index: 4;
}
.cta::after {
  /* Ellipse 5 */
  content: "";
  position: absolute;
  width: 90%;
  height: 70%;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 10%);
  z-index: 2;
  background: rgba(207, 153, 232, 0.9);
  filter: blur(156px);
}

footer {
  padding: 50px 0;
  background-color: #f5f5f5;
  position: relative;
  z-index: 4;
}
.footer-inner a {
  color: black !important;
  text-decoration: none;
}

.social-icon-wrapper a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #7362c9;
}

@media (max-width: 1200px) {
  .purple-cover-circle {
    width: 900px;
    height: 900px;
  }
  .blue-cover-circle {
    width: 1200px;
    height: 1200px;
  }
  .hero-content {
    padding: 100px 0;
  }
  .hero-text h1 {
    font-size: 53px;
  }
  .hero-text p br {
    display: none;
  }
}

@media (max-width: 992px) {
  .purple-cover-circle {
    width: 700px;
    height: 700px;
  }
  .blue-cover-circle {
    width: 900px;
    height: 900px;
  }
  .hero-content {
    padding: 50px 0 0 0 !important;
  }
  .hero-img {
    max-width: 500px;
    display: block;
    margin: 0 auto;
  }
  .modal-wrapper {
    max-width: 500px;
    padding: 60px 60px;
  }
  .social-icon-wrapper a {
    width: 40px;
    height: 40px;
  }
  .social-icon-wrapper a img {
    width: 10px;
  }
  .social-icon-wrapper a:nth-child(2) img,
  .social-icon-wrapper a:nth-child(3) img {
    width: 14px;
  }
  footer {
    padding: 30px 0;
  }
}

@media (max-width: 768px) {
  .modal-section {
    min-height: unset;
  }
  .purple-cover-circle {
    width: 100%;
  }
  .blue-cover-circle {
    width: 100%;
  }
  .logo-wrapper img {
    width: 130px;
  }
  .btn-1 {
    font-size: 16px;
  }
  .cta {
    padding: 80px 0 80px 0px;
  }
  .cta img:first-child {
    width: 90px;
  }
  .cta img:last-child {
    width: 140px;
  }
  .footer-inner {
    flex-direction: column;
    gap: 30px;
  }
}

@media (max-width: 576px) {
  header .container {
    flex-wrap: wrap;
    row-gap: 30px;
  }
  .hero-text h1 {
    font-size: 40px;
    line-height: 1.3;
  }
  .modal-wrapper {
    padding: 50px;
  }
  .cta img:first-child {
    width: 80px;
  }
  .cta .container img:last-of-type {
    width: 120px !important;
  }
  .footer-inner div:first-of-type {
    flex-direction: column;
    text-align: center;
    gap: 10px !important;
  }
  .modal-wrapper .btn-1 {
    width: 100%;
  }
}


#mc_embed_signup {
  background: #fff; /* Change as needed */
  clear: left;
  font: 14px Helvetica, Arial, sans-serif; /* Change as needed */
  width: 100%; /* Adjust the width as needed */
}

#mc_embed_signup form {
  padding: 20px; /* Add padding */
}

#mc_embed_signup input.email {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid var(--blue);
}

#mc_embed_signup input.button {
  width: 100%;
  background-color: var(--blue);
  color: white;
  border: none;
  padding: 10px 0;
  border-radius: 25px;
}

